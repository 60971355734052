<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container news" :class="{'white-container-list' : getListView}">

            <h2>Общественные палаты регионов</h2>

            <div class="news__tabs">
              <p v-for="(tab, index) in tabs" :class="{'active' : isActiveTab === index}" :key="`tab${index}`" @click="setTabNews(tab.route)">{{ tab.name }}</p>
            </div>

            <div class="news__button">
              <custom-select :key="`year`" class="news__select" :list='setListYears' @InputsContent="changeYear"
                  :placeholder="`${setListYears[setListYears.length-1].title}`"  />
              <tags-list-search :tags="getTagsList" @setTags="setTags"
                                :placeholder="`${ $store.getters.GET_LANG ? 'Введите теги через слэш' : 'Enter tags separated by slash'}`"/>

              <button type="button" @click="$store.dispatch('setView', false)" class="btn__icon btnBlock" :class="{'active' : getListView}"></button>
              <button type="button" @click="$store.dispatch('setView', true)" class="btn__icon btnList" :class="{'active' : !getListView}"></button>
            </div>

            <div class="news__feed">
                <div class="news__feed-content" :class="{'news__content-list' : getListView}" >
                    <!-- <news-block v-for="(card, i) in getNews" :card="card" :key="`news${i}`" :listView="getListView" /> -->
                    <dynamic-news-card v-for="(news, index) in getNews"
                               :is-list-view="getListView" :back-query="'public_chamber_news'"
                               :content="news"
                               :key="`newsCard${index}`"/>
                </div>
            </div>

          <pagination class="news__pagination"
                        :per-page="$store.getters.getPaginationLimit"
                        :current="$store.getters.getPaginationCurrentPage"
                        :total="$store.getters.getPaginationTotal"
                        @page-changed="getPage"/>

        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
// import NewsBanner from '@/components/news/NewsBanner'
import CustomSelect from '@/components/forms/CustomSelect'
import DynamicNewsCard from '@/components/newsList/DynamicNewsCard'
import Pagination from '@/components/Pagination'
import TagsListSearch from '@/components/news/TagsListSearch'

export default {
  name: 'PublicChamberNews',

  data () {
    return {
      tag: null,
      isActiveTab: 0,
      disableTags: true,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Общественные палаты регионов'
        }
      ],
      tabs: [
        { name: 'Новости', route: '/public_chamber_news' },
        { name: 'Палаты по округам', route: '/public_chamber_regions' },
        { name: 'Документы', route: '/public_chamber_materials' }
      ]

    }
  },

  mounted () {
    this.$store.dispatch('getNewsCommissions', 'public_chamber_news')
    this.getData()
    this.$store.commit('setPagination', {
      offset: (this.$store.getters.getPaginationLimit * (Number(this.$route.query?.page) - 1) || 1) || 0
    })
    this.$store.dispatch('setView', false)
    document.title = 'Общественные палаты регионов'
  },

  beforeDestroy () {
    this.$store.commit('setFilterYear', null)
    this.$store.commit('setPaginationLimit', 9)
    this.$store.commit('setPaginationOffset', 0)
  },

  beforeRouteLeave (to, from, next) {
    if (to.name === 'News') {
      this.$store.commit('setRouteParams', { path: from.path, page: this.$route.query?.page || 1 })
      next()
    } else {
      next()
    }
  },

  methods: {
    changeYear (e) {
      this.$store.commit('setFilterYear', e.title)
      this.getData()
    },

    setTabNews (route) {
      this.$router.push({ path: `${route}` })
    },

    getPage (page) {
      this.$router.push({ query: { page } })
      this.$store.commit('setIsSearch', true)
      this.$store.commit('setPaginationOffset', (page - 1) * this.$store.getters.getPaginationLimit)
      this.getData()
    },

    getData (isSearch) {
      this.$store.commit('setFilterSystemTagList', 'public_chamber_news')

      this.$store.commit('setPaginationLimit', 9)
      this.$store.commit('setPaginationOffset', (Number(this.$route.query.page || 1) - 1) * this.$store.getters.getPaginationLimit)
      // var dateToday = new Date().getFullYear()
      this.$store.dispatch('getPublicNews')

      this.$store.commit('setFilterSystemTagList', null)
      this.disableTags = false
    },

    /*
    InputsContent (event) {
      this.$store.commit('setFilterSystemTagList', 'public_chamber_news')
      this.$store.commit('setFilterTag', this.tag)
      this.$store.commit('setPaginationLimit', 9)
      this.$store.commit('setPaginationOffset', 0)
      this.$store.dispatch('getPublicNews', { date: event.title })
    },
    */

    setTags (tags) {
      if (!this.disableTags) {
        this.tag = tags.join('/')
        this.$store.commit('setFilterSystemTagList', 'public_chamber_news')
        this.$store.commit('setFilterTag', this.tag)
        this.$store.commit('setPaginationLimit', 9)
        this.$store.commit('setPaginationOffset', 0)
        this.$store.dispatch('getPublicNews')
      }
    }
  },

  computed: {
    getTagsList () {
      if (this.$store.getters.getFilterTag) {
        return this.$store.getters.getFilterTag.split('/')
      } else {
        return []
      }
    },

    getListView () {
      return this.$store.getters.getListView
    },

    getNews () {
      const content = this.$store.getters.getPublicNews
      return content // content.content && content.content.news ? content.content.news : []
    },

    setListYears () {
      const years = []
      var dateToday = new Date().getFullYear()
      for (let i = 2021; i <= dateToday; i++) years.push({ title: i })
      return years
    }
  },

  components: {
    TagsListSearch,
    Pagination,
    'bread-crumb': BreadCrumb,
    DynamicNewsCard,
    'custom-select': CustomSelect
  }
}
</script>

<style lang="scss" scoped>

.white-container{
    width: auto;
    margin: 0 3.8rem;
    padding: 2.5rem 2.94rem;
}

h2{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 2rem;
}

.news {
  display: flex;
  flex-direction: column;

  &__tabs {
    display: flex;
    padding-bottom: 1.375rem;
    border-bottom: .0625rem solid #D5D6D9;
    margin-bottom: 2rem;
    color: #003E78;

    span, .active {
      font-size: 0.8125rem;
      margin-right: 1.25rem;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      opacity: 0.6;

      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: #246CB7;
      opacity: 1;
    }

    .active::before {
      content: '';
      height: 100%;
      width: 100%;
      bottom: -1.45rem;
      position: absolute;
      border-bottom: 0.125rem solid #246CB7;
    }

  }

  &__button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.375rem;
  }
  &__select {
    margin-right: auto;
    width: 7.5rem;
  }

  // &__feed {

  //   &-content {
  //     display: flex;
  //     flex-wrap: wrap;
  //     margin: 0 -1.06rem;

  //     a {
  //       width: calc(33.333% - 2.125rem);
  //       margin: 0 1.06rem 2.125rem 1.06rem;

  //       cursor: pointer;
  //     }
  //   }
  // }
  &__feed-content{
    display: flex;
      flex-wrap: wrap;
    justify-content: space-between;

    > * {
      margin-bottom: 1rem;
    }

      div {
        width: calc(33.333% - 2.125rem);
        margin: 0 1.06rem 2.125rem 1.06rem;

        cursor: pointer;
      }
  }
  &__content-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    a {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
  }

  &__button-all {
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    background: #246CB7;
    padding: .5rem 1.88rem .44rem 1.88rem;
    border-radius: .187rem;
    margin: auto;
    cursor: pointer;
  }
}
.white-container-list{
  width: 61.4rem;
}
.news__tabs {
    display: flex;
    padding-bottom: 1.375rem;
    border-bottom: .0625rem solid #D5D6D9;
    margin-bottom: 2rem;
    color: #003E78;

    p, .active {
      font-size: 0.8125rem;
      margin-right: 1.25rem;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      opacity: 0.6;

      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: #246CB7;
      opacity: 1;
    }

    .active::before {
      content: '';
      height: 100%;
      width: 100%;
      bottom: -1.45rem;
      position: absolute;
      border-bottom: 0.125rem solid #246CB7;
    }

  }
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }

  .news {
    &__feed {
      margin: 0 -0.6125rem;
        &-content a {
          width: calc(50% - 1.25rem);
          margin: 0 0.6125rem 1.25rem;
          ::v-deep article{
            width: 100% !important;
          }
        }
    }
    &__content-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 0;
      a {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
      }
    }

  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .news {
      &__feed {
        &-content {
          a {
            width: 100%;

            ::v-deep article{
              width: 100% !important;
              .col{
                display: flex;
                margin-right: 0;
                .row{
                  flex-direction: column;
                }
              }
            }
          }
        }
      }

      &__content-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0;
        a {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
      }
      &__tabs {
        overflow-x: scroll;
        flex-wrap: nowrap;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        p{
          flex: 0 0 auto;
        }
        .active::before {
          content: '';
          height: 100%;
          width: 100%;
          bottom: -1.38rem;
        }
      }
    }
}
</style>
